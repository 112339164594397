<template>
  <div class="en_add">
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="rule"
        label-width="100px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="关联用户" prop="userId">
          <el-col :span="8">
            <fuzzy-selection
              :type="2"
              :value.sync="form.userId"
              ref="fuzzy"
            ></fuzzy-selection>
          </el-col>
        </el-form-item>

        <el-form-item label="嘉宾介绍" prop="desc">
          <el-col :span="8">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="form.desc"
              show-word-limit
              maxlength="150"
            >
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="讲师照片" prop="avatar">
          <up-img
            :action="{ action: 'user' }"
            :banner.sync="form.avatar"
            tipNew
          >
            <template #tip>
              上传图片比例：308*428
            </template>
          </up-img>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit">{{
            id ? '修改' : '提交'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import imgup from '@/components/upImg/index.vue'
import rules from '@/utils/rules'
import fuzzySelection from '@/components/fuzzySelection'
export default {
  components: {
    imgup,
    fuzzySelection
  },
  data() {
    return {
      id: null,
      form: {
        userId: '',
        avatar: ''
      },

      rule: {
        userId: [rules.req('请选择用户')],
        desc:[rules.req('请输入嘉宾介绍')],
        avatar:[rules.req('请选择讲师照片')]
      },
      action: {
        action: 'activity'
      },
      province: {},
      city: {},
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      cardList: [],
      tickList: [],
      first: true
    }
  },
  watch: {},
  computed: {},
  created() {
    sessionStorage.setItem('action', 'bonus')
    this.getform()
    // this.getEnv()
  },
  methods: {
    change(val) {
      this.form.desc = val
    },

    async onSubmit() {
      var url
      if (this.id) {
        url = '/admin/Activity/speakerEdit'
      } else {
        url = '/admin/Activity/speakerAdd'
      }
      this.$refs.form.validate()
      var { data: res } = await this.$http.post(url, {
        ...this.form,
        id: this.id
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.$router.replace('/activity_speaker')
      }
    },
    async getform() {
      if (!this.$route.query.id) {
        return
      }
      //   return
      this.id = this.$route.query.id
      const { data: res } = await this.$http.get(
        '/admin/Activity/getSpeakerDetail?id=' + this.id
      )
      if (res.errorCode == 200) {
        this.form = {
          ...res.data
        }
        this.$refs.fuzzy.options = [
          { id: res.data.userId, realName: res.data.realName }
        ]
      }
      //  this.form.initViews =
    }
  }
}
</script>

<style lang="less">
.en_add {
  .box {
    display: inline-block;
    width: 300px;
    input {
      border-color: #dcdfe6 !important;
    }
  }
}
</style>
